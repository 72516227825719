import React, { forwardRef, useRef } from "react";
import mergeRefs from "react-merge-refs";

// eslint-disable-next-line react/display-name
const Button = forwardRef((props, buttonRef) => {
  const {
    className,
    variant = "flat",
    children,
    active,
    width,
    disabled = false,
    style = {},
    onClick,
    Component = "button",
    ...rest
  } = props;
  const ref = useRef();

  return (
    <Component
      aria-pressed={active}
      data-variant={variant}
      ref={mergeRefs([ref, buttonRef])}
      className={className}
      disabled={disabled}
      onClick={onClick}
      style={{
        width,
        ...style,
      }}
      {...rest}
    >
      {children}
    </Component>
  );
});

export default Button;
